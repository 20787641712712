import "core-js/modules/es.array.push.js";
import { Button, Field, Form, Popup, Picker, Divider } from 'vant';
// import Vue from "vue";
// Vue.use(Popup);
import { areaAvailableCountries, areaAvailableCountriesDetail } from '../../api/common';
import { addressAdd } from '../../api/user';
export default {
  name: 'AddAddressPopup',
  components: {
    [Popup.name]: Popup,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
    [Picker.name]: Picker
  },
  props: {
    addAddressPopupShow: {
      type: Boolean
    }
  },
  data() {
    return {
      stateText: 'Select Country First     >  ',
      pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$|(^$)/,
      showAreaSelect: true,
      // addressPopupShow1: Boolean,
      provinceData: '',
      cityDates: '',
      // columns 为三个，即渲染出来的就是三级联动。
      columns: [{
        values: '',
        className: 'column1'
      }],
      addressForm: {
        firstName: '',
        lastName: '',
        country: '',
        areaPath: '',
        city: '',
        phone: '',
        address_1: '',
        address_2: '',
        postcode: '',
        email: ''
      },
      countryList: [],
      countrySelectShow: false,
      uploadPictureShow: false,
      areaSelectShow: false,
      // 省市区列表
      areaList: {
        province_list: {},
        city_list: {},
        county_list: {}
      },
      countyId: Number,
      stateId: Number,
      cityId: Number,
      countryId: Number,
      defaultCountry: ''
    };
  },
  // watch: {
  //   this.addressPopupShow1 : addressPopupShow;
  // },
  created: async function () {
    this.getCountryList();
  },
  methods: {
    areaSelectShowChange() {
      if (this.addressForm.country) {
        this.areaSelectShow = true;
      }
    },
    // 国家选择
    onCountrySelected(value) {
      this.addressForm.country = value;
      // eslint-disable-next-line eqeqeq
      if (this.defaultCountry != value) {
        // todo,需要加一个判断,国家变化了才变为"",还要加上地区选择联动
        this.addressForm.areaPath = '';
        this.countyId = '';
        this.stateId = '';
        this.cityId = '';
      }
      this.defaultCountry = value;
      this.countrySelectShow = false;
      // 修改国家id
      // console.log("value = " + value)
      areaAvailableCountries().then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          // eslint-disable-next-line eqeqeq
          if (res.data.data[i].name == value) {
            this.countryId = res.data.data[i].id;
            this.stateText = 'Select State';
          }
        }
      });
      this.getAreaConfig(value);
      // 根据国家过滤地区
    },

    // 地址select选择
    onAreaSelect(values) {
      this.addressForm.areaPath = values.filter(item => !!item).map(item => item.text).join('/');
      // 给add地址接口的参数赋值
      this.stateId = values[0].id;
      // this.cityId = values[1].id
      // console.log(values)
      // console.log(values.length)
      // if (values[2] !== undefined) {
      //   this.countyId = values[2].id
      // }

      this.areaSelectShow = false;
    },
    // 获取国家具体地址之后,将第一个显示到地区
    // cityAddressFill(values) {
    //   this.addressForm.areaPath = values
    //     .filter((item) => !!item)
    //     .map((item) => item.text)
    //     .join("/");

    // },
    // 提交新的地址
    onAddressAddSubmit(values) {
      // console.log(values);
      this.addressForm.firstName = values.firstName;
      this.addressForm.lastName = values.lastName;
      this.addressForm.country = values.country;
      this.addressForm.areaPath = values.area;
      this.addressForm.city = values.city;
      this.addressForm.phone = values.phone;
      this.addressForm.address_1 = values.address_1;
      this.addressForm.address_2 = values.address_2;
      this.addressForm.postcode = values.postcode;
      this.addressForm.email = values.email;
      this.addAddress();
    },
    // 获取用户默认地址
    // getUserDefaultAddress() {
    //   addressDefault().then((res) => {
    //     this.userInfo = res.data.data.user;
    //     this.defaultCountry=this.userInfo.locationInfo.country
    //     console.log("this.userInfo.locationInfo.country = " + this.userInfo.locationInfo.country)
    //     this.onCountrySelected(this.userInfo.locationInfo.country);
    //     if (res.data.data.address !== undefined) {
    //       console.log(" to if")
    //       this.userDefaultAddressId = res.data.data.address.id;
    //     } else {
    //       console.log("to  else")
    //       this.getCountryList();
    //     }
    //   });
    // },
    getCountryList() {
      areaAvailableCountries().then(res => {
        // 修改国家id,根据default接口返回的国家信息修改
        this.countryId = res.data.data[0].id;
        for (let i = 0; i < res.data.data.length; i++) {
          this.countryList.push(res.data.data[i].name);
          // eslint-disable-next-line eqeqeq
          if (this.defaultCountry == res.data.data[i].name) {
            this.countryId = res.data.data[i].id;
          }
        }
      });
    },
    getAreaConfig(countryName) {
      areaAvailableCountriesDetail(countryName).then(res => {
        this.provinceData = res.data.data;
        // 默认展示一级的数据
        this.columns[0].values = Object.values(res.data.data).map(function (e) {
          return {
            text: e.name,
            id: e.id
          };
        });
        // 默认展示二级的数据
        // this.columns[1].values = Object.values(res.data.data[0].cityList).map(function(e) {
        //   return { text: e.name, id: e.id }
        // })
        // // 默认展示三级的数据
        // this.columns[2].values = Object.values(res.data.data[0].cityList[0].countyList).map(function(e) {
        //   return { text: e.name, id: e.id }
        // })
        // 等待dom渲染完毕之后，在去显示 三级联动。
        this.$nextTick(function () {
          this.pageShow = true;
        });
      });
    },
    // vant picker选择器回调
    onChange(picker, values, index) {
      // 这里我有可能渲染的有问题，导致回调每次都修改了当前列，其他值没有修改，当前列？？（当前列什么鬼呀，什么垃圾啊？？？） 解释一下 ↓↓
      // 因为vant的 van-picker 回调只会返回你修改的那一列，比如现在为 [北京，北京，东城区]，你修改了省为[天津市，天津市，和平区]，但是vant的change回调会得到[天津市,北京,东城区],后面两个在回调中没有修改，不知道我的渲染方法有问题还是什么问题。
      // 所以我在这里判断change事件触发后，如果你修改了省份的话，回调里面省份会改变，但是市和区还是上一个省份的 市和区，这时我们执行回调修改省，市，区的时候，因为回调只有省改变了，市和区DOM改变了，但是回调里面没改变的问题（不晓得什么问题）,这时我们去找省下面的 市，我们取回调的市名称，如果在省下面没有找到这个市，默认展示第一个市，区也默认展示第一个市下面的区，如果遍历市能查到，就去展示对应的市。
      // console.log(picker, values, index)
      // 回调时修改第2列数据
      // picker.setColumnValues(1, this.cityDate(this.provinceData, values[0].text))
      // 回调时修改第3列数据
      // picker.setColumnValues(2, this.county(this.provinceData, values[1].text))
    },
    // 修改市 这里不再多说什么了根据自己的数据格式来
    // cityDate(data, province) {
    //   var that = this
    //   data.forEach(function(res) {
    //     // eslint-disable-next-line eqeqeq
    //     if (res.name == province) {
    //       console.log(res)
    //       that.cityDates = res
    //       console.log(res)
    //     }
    //   })
    //   return that.cityDates.cityList.map(function(res) {
    //     return { text: res.name, id: res.id }
    //   })
    //   // return 返回数据格式，因为我需要省市区code，所以我return的格式是对象,例:[{text:'北京市',code:'1'},{text:'河南省',code:'2'},...],如果你不需要code直接这样就可以 例['北京市','河南省',.....]
    // },

    // // 修改县 这里不再多说什么了根据自己的数据格式来
    // county(data, county) {
    //   var that = this
    //   var countyDate = ''
    //   // 因为vant的 van-picker 回调只会返回你修改的那一列，比如现在为 [北京，北京，东城区]，你修改了省为[天津市，天津市，和平区]，但是vant的change回调会得到[天津市,北京,东城区],后面两个在回调中没有修改，不知道我的渲染方法有问题还是什么问题。
    //   // 所以我在这里判断change事件触发后，如果你修改了省份的话，回调里面省份会改变，但是市和区还是上一个省份的 市和区，这时我们执行回调修改省，市，区的时候，因为回调只有省改变了，市和区DOM改变了，但是回调里面没改变的问题（不晓得什么问题）,这时我们去找省下面的 市，我们取回调的市名称，如果在省下面没有找到这个市，默认展示第一个市，区也默认展示第一个市下面的区，如果遍历市能查到，就去展示对应的市。
    //   that.cityDates.cityList.forEach(function(res) {
    //     // eslint-disable-next-line eqeqeq
    //     if (res.name == county) {
    //       countyDate = res
    //     }
    //   })
    //   // 这里如果没有找到对应的县
    //   // eslint-disable-next-line eqeqeq
    //   if (countyDate == '') {
    //     countyDate = that.cityDates.cityList[0]
    //   }
    //   // return 县
    //   return countyDate.countyList.map(function(res) {
    //     return { text: res.name, id: res.id }
    //   })
    //   // return 返回数据格式，因为我需要省市区code，所以我return的格式是对象,例:[{text:'北京市',code:'1'},{text:'河南省',code:'2'},...],如果你不需要code直接这样就可以 例['北京市','河南省',.....]
    // },
    addAddress() {
      // console.log("hhhhhhhhhh")
      const newAddress = {
        consignee: this.addressForm.firstName + ' ' + this.addressForm.lastName,
        firstName: this.addressForm.firstName,
        lastName: this.addressForm.lastName,
        countryId: this.countryId,
        stateId: this.stateId,
        phone: this.addressForm.phone,
        city: this.addressForm.city,
        addressLine1: this.addressForm.address_1,
        addressLine2: this.addressForm.address_2,
        postalCode: this.addressForm.postcode,
        email: this.addressForm.email
      };
      // console.log("hhhhhhhhhh")
      addressAdd(newAddress).then(res => {
        const callbackData = {
          addressId: res.data.data.address.id
        };
        this.$emit('callbackForAdd', callbackData);
      });
    }
  }
};